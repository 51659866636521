import React, { useEffect } from "react";
import FetchBikes from "../../fetchBikes";
import { useState, useContext, useRef } from "react";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import CloseIcon from '../../assets/icon-cross.svg'
import ArrowIcon from '../../assets/icon-forward-arrow.svg'
import { v4 as uuidv4 } from 'uuid';
import { getTodayDate } from "../../utils";
import { toast } from "react-toastify";
import { validateInput } from "../../utils";

export default function AddBikeNew() {
    
    const { userDetails, addBike } = useContext(UserContext);
    const {modalState, toggleModalState, setLoading, setCurrentBike} = useContext(WindowContext)
    const isAddBikeVisable = modalState.addBikeNewModal
    const [ errMsg, setErrMsg ] = useState('')
    const [input, setInput] = useState(null)
    const [fetchedData, setFetchedData] = useState('');
    const inputRef = useRef(null)
    const currentYear = "2022"
    const [yearListValue, setYearListValue] = useState(currentYear)
    const [newRequest, setNewRequest] = useState(false)

    const yearListRef = useRef(null);


    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        
        if (isAddBikeVisable) {
            toggleModalState("addBikeNewModal")
        }
    }

    // Update input state when any input is changed
    const handleInputChange = (event) => {
        setNewRequest(true)
        setInput(event.target.value)
    }


    // Get Data from fetch.js
    const handleFetchedData = (data) => {
        
        setFetchedData(data)
    }
    
    // Lets user continue without finding thier bike via the API
    const handleContinueWithoutBtn = () => {
        
        if (validateInput(inputRef.current.value)) {

            let makeCapitalised = null
            let modelCapitalised = null
            
            // Check if str contans more than one word
            if(input.split(" ").length > 1) {
                makeCapitalised = input.split(" ")[0].replace(/\b\w/g, char => char.toUpperCase()); 
                modelCapitalised= input.split(" ").slice(1).join(" ") 
            }
            else {
                makeCapitalised = input
            }
    
            setLoading(true)
            
            try {
              const newBike = {
                uuid: uuidv4(),
                logs: [],
                dashboard: {
                    bikeImagePath: "",
                    motExpiry: getTodayDate(),
                    taxExpiry: getTodayDate(),
                    tyrePressureFront: "0",
                    tyrePressureRear: "0",
                    compressionFrontHS: "0",
                    compressionRearHS: "0",
                    compressionFrontLS: "0",
                    compressionRearLS: "0",
                    reboundFrontHS: "0",
                    reboundRearHS: "0",
                    reboundFrontLS: "0",
                    reboundRearLS: "0",
                    todoList: []
                },
                specs: {
                    make: makeCapitalised,
                    model: modelCapitalised,
                    year: ""
                    }
                }
    
                updateUsersBikes(newBike);
                setCurrentBike(newBike);
          
              // Closes modal
              if (modalState.addBikeNewModal) {
                toggleModalState("addBikeNewModal")
              }
            }
        
            catch(err) {
              console.log(err)
            }
        
            finally {
              setLoading(false)
            }
          
        }
        else {
            toast.error("Please use a valid input. You can't leave this blank or use any special characters.", {
                position: "top-center",
              });
        }
        
      };

// Push bike object to user's data
  async function updateUsersBikes(bikeObj) {
    if (!userDetails.bikes.find(bike => bike.uuid === bikeObj.uuid)) {
      addBike(bikeObj);
    }
  }
  
  let listOfYears = []
  for(let i = parseInt(currentYear); i >= 1960; i--) {
    listOfYears.push(i)
  }

  const yearListJSX = listOfYears.map(year => {
        return (
            <option value={year} key={year}>{year}</option>
        )
  })

  // When the user changes the year selection dropdown
  const handleYearChange = (e) => {
    setYearListValue(e.target.value);
    setNewRequest(true)
  };

  

    return (
        <>
            <div className="modal-overlay" style={isAddBikeVisable ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="add-bike-modal" style={isAddBikeVisable ? {display : "block"} : {display : "none"}}>
                <div className="modal-header">
                    <h2 className="modal-title heading-xl">Add Bike New</h2>
                    <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                </div>
                <form>
                    <div className="add-bike-input-wr">
                        <input type="text" className="modal-input" placeholder="<Make> <Model>" id="add-bike-input" onChange={handleInputChange} maxLength="100" required autoComplete="off" ref={inputRef}/> 
                        <label htmlFor="add-bike-input" className="heading-m">Year:</label>
                        {yearListJSX && <select
                            id="bike-year"
                            name="bike-year"
                            value={yearListValue}
                            onChange={handleYearChange}
                            className="dropdown-list"
                            ref={yearListRef}
                        >
                            {yearListJSX}
                        </select>}
                    {/* Fetch bike from API */}
                    {input &&
                        <FetchBikes
                            input={input}
                            inputRef={inputRef}
                            year={yearListValue}
                            onFetchedData={handleFetchedData}
                            setNewRequest={setNewRequest}
                            newRequest={newRequest}
                        />
                    }
                    </div>

                    <p className="err-msg body-s text-center">We're currently using a free API service for our bike data, which may have some limitations. As a result, the latest models available are from 2022.</p>
                    <div className="modal-footer">
                        <p>-OR-</p>
                        <a className="continue-without-link text-center" onClick={handleContinueWithoutBtn}>Continue without finding your bike
                            <img src={ArrowIcon} alt="forward-arow" className="arrow-icon" />
                        </a>
                        <p className="body-s text-center">-There will be no bike specs available-</p>
                    </div>
                    {errMsg !== '' && <p className="err-msg body-s">{errMsg}</p>}
                </form>
            </div>
        </>
    )
}