import React, { useRef, useState } from 'react';
import { toast } from "react-toastify";

const UploadPhoto = ({ onUpload }) => {
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const maxFileSizeMB = 2; // Maximum file size in MB
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes
  const maxLargeWidth = 2560; // Maximum width for large image
  const maxLargeHeight = 1440; // Maximum height for large image
  const smallWidth = 800; // Width for small image
  const smallHeight = 600; // Height for small image

  // Handles file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;

          // Create canvas for processing
          const context = canvasRef.current.getContext('2d');

          // Function to resize the image while maintaining the aspect ratio
          const resizeImage = (maxWidth, maxHeight) => {
            // Calculate aspect ratio
            const aspectRatio = img.width / img.height;

            let width = maxWidth;
            let height = maxHeight;

            // Adjust dimensions to maintain aspect ratio
            if (img.width > img.height) {
              height = Math.round(maxWidth / aspectRatio);
            } else {
              width = Math.round(maxHeight * aspectRatio);
            }

            // Resize the canvas and draw the image
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            context.clearRect(0, 0, width, height);
            context.drawImage(img, 0, 0, width, height);

            // Return the resized image as a Data URL
            return canvasRef.current.toDataURL('image/jpeg', 0.75);
          };

          // Create large image URL
          let largeWidth = originalWidth;
          let largeHeight = originalHeight;

          // Scale down large image if dimensions exceed 2560x1440
          if (originalWidth > maxLargeWidth || originalHeight > maxLargeHeight) {
            const largeScale = Math.min(maxLargeWidth / originalWidth, maxLargeHeight / originalHeight);
            largeWidth = Math.round(originalWidth * largeScale);
            largeHeight = Math.round(originalHeight * largeScale);
          }

          // Create small image URL scaled to 800x600
          const smallDataUrl = resizeImage(smallWidth, smallHeight);

          // Create large image URL scaled to maximum allowed resolution
          const largeDataUrl = resizeImage(largeWidth, largeHeight);

          // Convert the compressed largeDataUrl to a Blob
          fetch(largeDataUrl)
            .then(res => res.blob())
            .then(blob => {
              // Check if the compressed file size exceeds the limit
              if (blob.size > maxFileSizeBytes) {
                toast.error(`Compressed image is too large (${(blob.size / 1024 / 1024).toFixed(2)} MB). Please select a smaller image.`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // Optionally clear the selection
                setSelectedFile(null);
                return;
              }
              
              // Proceed with upload if size is within the limit
              onUpload(smallDataUrl, largeDataUrl, false);
            })
            .catch(error => {
              console.error('Error processing the image:', error);
            });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // Triggers the file input dialog
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {/* Display the button to upload a photo */}
      <button type="button" className='primary-btn' onClick={triggerFileInput}>
        Select Photo
      </button>
      {/* Display the selected photo in a canvas if available */}
      {selectedFile && (
        <div className='video-frame' style={{ display: "none" }}>
          <canvas ref={canvasRef} width="100%" height="auto" />
        </div>
      )}
      {/* Hidden file input element */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default UploadPhoto;
