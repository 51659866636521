import React from "react";
import { useContext } from "react";
import { WindowContext } from "../../context/WindowContext";

export default function AddLogPage () {
    
    let {toggleModalState} = useContext(WindowContext)

    // Shows addNewLog Modal
    const handleAddLogBtn = () => {
        toggleModalState("addNewLogModal")
    
    }

    return (
        <>
            <div className="main-fallback-wr">
                <div className="fallback-wr">
                    <p>Record all your maintanence work here, from replacing brake pads to full engine rebuilds. Don’t forget to upload photos to document your work!</p>
                    <button className="primary-btn" onClick={handleAddLogBtn}>Add new Log</button>
                </div>
            </div>
        </>
    )
}

