import React, { useContext, useEffect, useState } from "react";
import EditIcon from '../../assets/icon-edit.svg';
import CheckedIcon from '../../assets/icon-checked.svg';
import VerifiedIcon from '../../assets/icon-photo-is-verified.svg';
import AddLogIcon from '../../assets/icon-add-log.svg';
import ExportLogIcon from '../../assets/icon-export.svg';
import AddLogPage from '../Pages/AddLogPage';
import { WindowContext } from '../../context/WindowContext';
import { UserContext } from '../../context/UserContext';
import AddBikePage from "./AddBikePage";
import { exportToPdf } from '../../components/Modules/ExportToPDF'

export default function LogBook() {
    
    const { currentBike, toggleModalState, updateCurrentLog, setModalPhotos, setCurrentPage, setCurrentImgToEnlarge} = useContext(WindowContext);
    const {userDetails } = useContext(UserContext)

    const bikeCount = userDetails.bikes.length
    
    useEffect(() => {
        setCurrentPage("logbook")
        setModalPhotos([])
    }, [])

    // Display the addNewLog Modal
    const handleShowAddLogModal = () => {
        toggleModalState("addNewLogModal")
    }

    // Updates currentLogState and shows the edit log Modal
    const handleEditLogBtn = (e) => {
        
        const clickedLogObj = currentBike.logs.filter(log => log.id === e.target.id)
        updateCurrentLog(clickedLogObj[0])
        toggleModalState("editLogModal")
    }

    // Exports currentBike Details to PDF
    const handleExportToPdf = () => {
        exportToPdf(currentBike)
    }

    // Shows a modal with an enlarged version of the image
    const handleShowEnlargeImgModal = async (photo) => {
        await setCurrentImgToEnlarge(photo)
        toggleModalState("enlargeImgModal")
    }
    
    // Loops over the logs and creates the relavent JSX
    currentBike.logs.sort((a, b) => new Date(a.date) - new Date(b.date))
    const currentBikeLogsJSX = currentBike.logs.map(log => {
        return (
                    <div className={`log-wr ${log.isVerified && "verified-log"}`} key={log.id}>
                        <div className="log-header">
                            <div className="left-col">
                                {log?.isVerified && <div className="verified-title-wr">
                                    <img src={VerifiedIcon} alt="verified Icon" className="verified-log-icon"/>
                                    <p className="verified-log-title">Verified</p>
                                </div>}
                                <h2 className="log-date heading-l">{log.date.split("-").reverse().join("/")}</h2>
                            </div>
                            <div className="right-col">
                                <button className={`edit-btn ${log.isVerified && "verified-bg"}`} id={log.id} onClick={(e) => handleEditLogBtn(e)} >
                                    edit
                                    <img src={EditIcon} alt="edit icon" className="edit-icon" />
                                </button>
                            </div>
                        </div>
                        
                        <p className="log-mileage">{log.mileage} miles / hrs</p>

                        <div className="log-tasks-wr">
                            <h3 className="log-tasks-title heading-m">
                                Tasks
                            </h3>
                            <ul className="log-tasks-list">
                                {log.tasks.map(task => {
                                    
                                    let taskDescription = task.taskDesc
                                    // Capitalize the first letter of the string
                                    taskDescription = taskDescription.charAt(0).toUpperCase() + taskDescription.slice(1);
                                    return (
                                        <li className="log-task" key={task.id}>{taskDescription}</li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="log-additional-info">
                            <div className="cost-dealer-row-wr">
                                <h3 className="heading-m">Cost</h3>
                                <p className="log-cost">£{log.cost}</p>
                                {log.completedByShop === "Yes" &&
                                <>
                                    <h3 className="heading-m">Dealer</h3>
                                    <img src={CheckedIcon} alt="Checked Icon" className="checked-icon" />
                                </>}
                            </div>
                            {log.notes === "" ? <></> : 
                            <div className="log-notes-wr">
                                <h3 className="heading-m">Notes</h3>
                                <p className="log-notes">{log.notes}</p>
                            </div>}
                        </div>
                        {log.photos.length !== 0 && 
                        <div className="log-photos-wr">
                            <h3 className="heading-m">Photos</h3>
                            <div className="log-photos-grid">
                                {log.photos.map((photo, index) => {
                                    
                                    return (
                                        <div className={`log-photo-container `} key={index}>
                                            <button className={`log-photo-wr`} onClick={() => handleShowEnlargeImgModal(photo)}>
                                                <img src={photo.smallImageURL} alt="log photo" className={`log-photo ${photo.isVerified && "verified-photo"}`} />
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                    </div>
        )
    }).reverse();

    return (
        <>
            {bikeCount > 0 ?
            <div className="main-log-wr">
                
                {/* If there are no logs, prompt user to add a new log */}
                {currentBike.logs.length === 0 ? <AddLogPage /> : 
                    <div className="logs-grid" >
                        {currentBikeLogsJSX}
                        <div className="add-new-log-wr">
                            <button className="add-new-log-btn" onClick={handleShowAddLogModal}>
                                <img src={AddLogIcon} alt="Add log icon" className="add-log-icon" />
                                Add New Maintenance Log
                            </button>
                        </div>
            
                        <div className="export-log-wr">
                            <button className="export-log-btn" onClick={handleExportToPdf}> 
                                <img src={ExportLogIcon} alt="Export log icon" className="export-icon" />
                                Export Log to a PDF
                            </button>
                        </div>
                    </div>
                }
                
            </div>
            :
            <AddBikePage />}
        </>
    )
}
