import React from "react";
import { useContext } from "react";
import { WindowContext } from "../../context/WindowContext";

export default function AddBikePage () {
    
    let {modalState, toggleModalState} = useContext(WindowContext)

    // Shows Add Bike Modal
    const handleAddBikeBtn = () => {
        if (modalState.addBikeNewModal === false) {
                toggleModalState("addBikeNewModal")
            }
    }

    return (
        <>
            <div className="main-fallback-wr">
                <div className="fallback-wr">
                    <p className="text-center">Get started by adding your bike!<br />
                    If we have it on our database, we’ll be able to provide you with handy specs for your bike.</p>
                    <button className="primary-btn" onClick={handleAddBikeBtn}>Add Bike</button>
                </div>
            </div>
        </>
    )
}

