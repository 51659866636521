import React, { useContext, useEffect } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { UserContext } from "../../context/UserContext";
import { WindowContext } from "../../context/WindowContext";
import { Routes, Route } from "react-router-dom";


import Layout from '../Layout/Layout'
import Dashboard from '../Pages/Dashboard'
import About from '../Pages/About'
import Donate from '../Pages/Donate'
import AddNewLogModal from '../Modals/AddNewLog';
import AddVINModal from '../Modals/AddVINModal';
import AddBikeNewModal from '../Modals/AddBikeNew';
import EditLogModal from '../Modals/EditLog';
import DeleteConfirmLogModal from '../Modals/DeleteConfirmLogModal';
import DeleteConfirmBikeModal from '../Modals/DeleteConfirmBikeModal';
import BikeSpecsModal from '../Modals/BikeSpecsModal';
import AddPhotoModal from '../Modals/AddPhotoModal'
import EnlargeImgModal from "../Modals/EnlargeImgModal";
import FeedbackModal from "../Modals/FeedbackModal";
import LoadingOverLay from "../Layout/LoadingOverlay";
import LogBook from '../Pages/LogBook'

function Profile(props) {
  
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { loading, setLoading, setCurrentBike, currentBike, resetCurrentLog} = useContext(WindowContext);
  const user = props.user
  
  // Update userDetails, anytime currentBike is Updated
  useEffect(() => {
    if(currentBike && userDetails) {
      
      const currentBikeIndex = userDetails.bikes.findIndex(bike => bike.uuid === currentBike.uuid)
      let newCurrentBikeArr = userDetails.bikes
      newCurrentBikeArr[currentBikeIndex] = currentBike
      
      setUserDetails(prev => {
        return {
         ...prev,
         bikes: newCurrentBikeArr
        }
      })
    }

    // When a log has been added or removed, resets current log ready for the new log to be added
    resetCurrentLog()

  },[currentBike])


// Fetches User data from Firebase DB and creates it if it doesn't exist
const fetchUserData = async () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
          resolve(docSnap.data());
        } else {
          console.log("User document does not exist, creating new document...");

          // Define default data for a new user document
          const defaultUserData = {
            name: user.displayName || "Anonymous",
            email: user.email,
            bikes: [], // Default to an empty array or any other default structure
          };

          try {
            // Create a new document for the user with default data
            await setDoc(docRef, defaultUserData);
            setUserDetails(defaultUserData);
            resolve(defaultUserData);
            console.log("User document created successfully.");
          } catch (error) {
            console.error("Error creating user document:", error);
            reject("Error creating user document");
          }
        }
      } else {
        console.log("No user is logged in");
        reject("No user is logged in");
      }
    });
  });
};


  // Pushes userDetails to firebase DB
  const saveUserDetails = async () => {
    
    return new Promise(async (resolve, reject) => {
      
      if (userDetails) {
        
        try {
          await setDoc(doc(db, "Users", user.uid), userDetails);
          resolve("userDetails Successfully Pushed to DB");
        
        } catch (error) {
          console.error("Error updating user details:", error);
          reject("Error updating user details:", error);
        }
      }
    });
  };

  // Updates DB any time userDetails have been changed
  useEffect(() => {
    
    const updateFirestore = async () => {
      
      setLoading(true);
      
      if (userDetails) {
        
        try {
          
          const dbUpdated = await saveUserDetails();
          
          if (dbUpdated) {
            setLoading(false);
          }
        
        } catch (error) {
          console.log(error);
        }
      }
    };
    
    updateFirestore();
  }, [userDetails, setLoading]);


  // Loads user details and sets currentBike
  useEffect(() => {
    
    const loadUserIntoProfile = async () => {
      
      setLoading(true);
      
      try {
        
        const userData = await fetchUserData();
        
        if (userData && userData.bikes && userData.bikes.length > 0) {     
          if (currentBike.uuid === "") {
            setCurrentBike(userData.bikes[0]);
          }
        } else {
          console.log("No bikes found on initial load");
        }
      
      } catch (error) {
        console.log(error);
      
      } finally {
        setLoading(false);
      }
    };
    loadUserIntoProfile();
  }, []);

  return (
    <div>
      {/* Rendering Loading screen if the app is still loading or still fetching userDetails */}
      {!loading && userDetails && currentBike ? (

          <>
            <Routes>
              <Route element={<Layout />}>
                <Route path="logbook" element={<LogBook />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="about" element={<About />} />
                <Route path="support-us" element={<Donate />} />
              </Route>
            </Routes>

            {/* Modals */}
            <AddBikeNewModal />
            <AddNewLogModal />
            <EditLogModal />
            <DeleteConfirmLogModal />
            <DeleteConfirmBikeModal />
            <BikeSpecsModal />
            <AddPhotoModal />
            <EnlargeImgModal />
            <AddVINModal />
            <FeedbackModal />

            
          </>

      ) : (
        <LoadingOverLay />
      )}
    </div>
  );
}

export default Profile;
