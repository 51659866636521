import React, { useState } from "react";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import validator from "validator";
import Logo from '../../assets/logo-dark.svg'
import { sendPasswordResetEmail } from "firebase/auth";
import LoadingSpinner from '../Modules/LoadingSpinner'


function PasswordReset() {
  
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("")

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    
    if (!validator.isEmail(email)) {
      toast(`Sorry, invalid email, please check the email address you have entered is correct`)
    }
    else {
      
      setLoading(true)
      try {
        sendPasswordResetEmail(auth, email)
        toast(`A password reset email has been sent to ${email}. Check your Junk mail.`)
      } 
      
      catch (error) {
        toast(`Sorry, something went wrong. ${error}`)
      }
      
      finally {
        setLoading(false)
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div className="modal-header">
          <img src={Logo} alt="logo" className="logo"/>
        </div>
        <h2 className="text-center">Password reset</h2>
        <form onSubmit={handleSubmit}>
          <div className="modal-email-wr">
            <h3 className="heading-m">Email address</h3>
            <input
              type="email"
              className="modal-input"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
              required
            />
          </div>
          <div className="auth-footer">
            {!loading ?
            <button type="submit" className="primary-btn">
              Reset Password
            </button>
            :
            <div style={{
              width: '100%',
              display: 'flex'
            }}>
              <LoadingSpinner />
            </div>
            }
            <div className="modal-links-wr justify-content-center">
                <a className="primary-link body-l" href="/login">Back to login</a>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
}

export default PasswordReset;
