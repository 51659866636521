import {React, useEffect, useContext} from "react";
import Logo from '../../assets/logo-dark.svg'
import OurMissionImg from '../../assets/about-our-mission-img.png'
import ExampleLogImg from '../../assets/about-log-example.png'
import ExampleSpecsImg from '../../assets/about-specs-example.png'
import HappyBikerImg from '../../assets/about-happy-biker-img.png'
import UploadPhotoImg from '../../assets/about-upload-photo-img.png'
import DashboardBikeImg from '../../assets/about-dashboard-bike-img.png'
import VerifiedIcon from '../../assets/icon-photo-is-verified.svg'
import PDFIcon from '../../assets/icon-pdf.png'
import { Link } from 'react-router-dom';
import {getTodayDate} from '../../utils'
import { WindowContext } from '../../context/WindowContext';

export default function AboutPage () {

    const {setCurrentPage} = useContext(WindowContext);

    useEffect(() => {
        setCurrentPage("about")
    }, [])
    
    return (
        <div className="about-center-div">
            <div className="about-wr">
                
                    <h1 className="heading-xxl big-title">Our Mission</h1>
                    <img src={OurMissionImg} alt="Our mission img" className="about-img"/>
                    <p className="text-center">MotoDiary is designed for motorcycle enthusiasts who prefer handling their own bike maintenance instead of relying on a shop. If you're still keeping logs on paper and storing stacks of receipts, MotoDiary offers a seamless digital solution to manage your bike’s upkeep effortlessly.</p>
                    <img src={ExampleLogImg} alt="Example Log" className="example-log"/>
                    <p className="text-center">By creating a digital record of your maintenance, you add tangible value when it’s time to sell your bike. Instead of asking potential buyers to take your word for it, you can provide verifiable proof that your bike has been meticulously maintained.</p>
                    <h1 className="heading-xxl">Key Features</h1>
                    <h2 className="sub-heading-orange">Specs for your bike</h2>
                    <img src={ExampleSpecsImg} alt="Example Log" className="example-log"/>
                    <p className="text-center">We leverage a third-party database to offer detailed information about your motorcycle, accessible through the <Link to="/profile/dashboard" className="primary-link">Dashboard</Link> under <strong>Bike Specs</strong>. This gives you quick access to essential data when you need it.</p>
                    <h2 className="sub-heading-orange">Upload Photo Evidence</h2>
                    <img src={UploadPhotoImg} alt="Example Log" className="about-img"/>
                    <p className="text-center">Easily upload up to four photos per maintenance log, providing visual documentation of your work. Whether it's your bike in pieces, receipts for parts, or a snapshot of the odometer, MotoDiary allows you to present clear proof of your maintenance efforts.</p>
                    <h2 className="sub-heading-orange">Photo Verification (<img src={VerifiedIcon} alt="verified-icon" className="verified-icon"/>)</h2>
                    <p className="text-center">For added credibility, MotoDiary provides photo verification. If you take a photo directly through the app on the day of the log, we verify its authenticity, offering reassurance that the image was captured in real-time. Photos uploaded from your gallery cannot be verified, as they may be older or sourced elsewhere.</p>
                    <h2 className="sub-heading-orange">Centralised Dashboard</h2>
                    <img src={DashboardBikeImg} alt="Dashboard Bike Image" className="dashboard-bike-img"/>
                    <h1 className="heading-xxl about-bike-name">Ducati 1199 Panigale R</h1>
                    <p className="vin-number">VIN:395867364987</p>
                    <div className="reg-input">MO70 DRY</div>
                    <p className="text-center">The MotoDiary <Link to="/profile/dashboard" className="primary-link">Dashboard</Link> is your go-to hub for all bike-related information. Forget the hassle of remembering tyre pressure preferences or suspension settings—store it all here, alongside reminders for MOT and tax expiry dates, a todo list and more.</p>
                    <h2 className="sub-heading-orange">Export Maintenance Logs to a PDF</h2>
                    <img src={PDFIcon} alt="PDF Icon" className="pdf-icon"/>
                    <p className="text-center">Prefer to keep a physical backup?  MotoDiary allows you to export your maintenance history into a clean, printable PDF. You can easily share this file with potential buyers or keep it for personal reference, ensuring you always have a detailed history on hand.</p>
                    <img src={HappyBikerImg} alt="Happy Biker Image" className="about-img"/>
                    <p className="text-center">MotoDiary streamlines the process of motorcycle maintenance, offering you a smarter way to stay organized and ensuring your bike is always in top condition, with proof to back it up.</p>
                    <div className="footer-logo">
                        <img src={Logo} alt="logo" className="logo" />
                        <p className="copyright-text">© {getTodayDate().slice(0,4)} MotoDiary. All rights reserved</p>
                    </div>
            </div>
        </div>
    )
}
