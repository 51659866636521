import React from "react";
import { useState, useContext, useRef } from "react";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import CloseIcon from '../../assets/icon-cross.svg'
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { validateVIN } from "../../utils";
import Dashboard from "../Pages/Dashboard";

export default function AddVINModal() {
    
    const { userDetails, addBike } = useContext(UserContext);
    const {modalState, toggleModalState, setLoading, setCurrentBike} = useContext(WindowContext)
    const isAddVINVisable = modalState.AddVINModal
    const [ errMsg, setErrMsg ] = useState('')
    const [input, setInput] = useState('')
    const inputRef = useRef(null)

    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        
        if (isAddVINVisable) {
            toggleModalState("AddVINModal")
        }
    }
    
    // Check for valid VIN number and update VIN to currentBike
    const handleAddVINBtn = (e) => {
        e.preventDefault()
        const input = inputRef.current.value.toUpperCase()
        if (validateVIN(input)) {
            setCurrentBike((prev) => {
                return {
                    ...prev,
                    dashboard: {
                        ...prev.dashboard,
                        VIN: input
                    }
                }
            })
            toggleModalState("AddVINModal")
        }
        else {
            toast.error("The VIN you have entered is incorrect. It needs to be 17 characters long and contains only allowed characters (A-Z, 0-9)", {
                position: "top-center",
              });
        }
    }

    return (
        <>
            <div className="modal-overlay" style={isAddVINVisable ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="add-VIN-modal" style={isAddVINVisable ? {display : "block"} : {display : "none"}}>
                <div className="modal-header">
                    <h2 className="modal-title heading-l">Add VIN Number</h2>
                    <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                </div>
                <form>
                    <input type="text" className="modal-input" placeholder="VIN" id="add-vin-input"maxLength="17" required autoComplete="off" ref={inputRef}/> 
                    <button className="primary-btn" onClick={handleAddVINBtn}>Add VIN</button>
                    <p className="err-msg body-s">You CANNOT edit the VIN number once it has been added. This locks the maintanence history to one physical bike.</p>
                </form>
            </div>
        </>
    )
}