import React, { useContext, useState} from "react";
import CrossIcon from "../../assets/icon-cross.svg";
import CloseIcon from '../../assets/icon-cross.svg'
import AddPhotoIcon from "../../assets/icon-add-photo.svg";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import { v4 as uuidv4 } from 'uuid';
import { getTodayDate, verifyModalPhotos } from "../../utils";
import { toast } from "react-toastify";

export default function AddNewLog() {

    const { modalState, addLog, toggleModalState, modalPhotos, setModalPhotos, currentLog, setLoading, setCurrentImgToEnlarge } = useContext(WindowContext);
    const { updateCurrentBike } = useContext(UserContext);
    const isAddLogVisible = modalState.addNewLogModal;
    
    const [date, setDate] = useState(getTodayDate());
    const [mileage, setMileage] = useState("");
    const [cost, setCost] = useState("");
    const [completedByShop, setCompletedByShop] = useState("No");
    const [tasks, setTasks] = useState([]);
    const [taskInput, setTaskInput] = useState("");
    const [notes, setNotes] = useState("");
    const [errMsg, setErrMsg] = useState("");

    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        
        if (isAddLogVisible) {
            toggleModalState("addNewLogModal")
        }
    }

    // If user input is valid, add new log to user state and currentBike
    const handleSubmitLog = async (e) => {
        e.preventDefault();

        setLoading(true)

        
        // Check for valid input
        if (!isValidDate(date)) {
            toast.error("Sorry! Date cannot be in the future.", {
                position: "top-center",
              });
            setLoading(false)
        } else if (mileage === "") {
            toast.error("Sorry! Mileage cannot be empty.", {
                position: "top-center",
              });
            setLoading(false)
        } else if (tasks.length === 0) {
            toast.error("Sorry! You need to add atleast 1 task", {
                position: "top-center",
              });
            setLoading(false)
        } else {
            setErrMsg("");
            
            
            try {
                
                const updatedPhotos = await verifyModalPhotos(modalPhotos, setModalPhotos, date).then(({updatedPhotos, allPhotosAreVerified}) => {
                    return ("Updated photos:", {updatedPhotos, allPhotosAreVerified});
                  }).catch((error) => {
                    console.error("Error updating photos:", error);
                  });
    
                const newLog = {
                    id: currentLog.id,
                    date: date,
                    mileage: mileage,
                    cost: cost,
                    completedByShop: completedByShop,
                    tasks: tasks,
                    notes: notes,
                    photos: updatedPhotos.updatedPhotos,
                    isVerified: updatedPhotos.allPhotosAreVerified
                };

                // Update log to user state
                const updatedBike = await addLog(newLog);
                await updateCurrentBike(updatedBike);
                
                // clearInputFields and close modal
                toggleModalState("addNewLogModal")
                clearInputFields()
            
            } catch (error) {
            
                console.error("An error occurred while updating the log and bike:", error);
                setErrMsg("An error occurred while processing your request.");
            }

            finally {
                setLoading(false)
            }
        }
    };

    const clearInputFields = () => {
        
        setDate(getTodayDate());
        setMileage("");
        setCost("")
        setCompletedByShop("No");
        setTasks([]);
        setTaskInput("");
        setNotes("");
        setErrMsg("");
        setModalPhotos([])
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return false;
        }
        // Check if the date is not later than today
        if (date > today) {
            return false;
        }
        return true;
    };

    // Adds a new task to state and resets task input field
    const handleAddNewTask = () => {
        if (taskInput.trim() !== "") {
            setTasks((prevTasks) => [
                ...prevTasks,
                {
                    id: uuidv4(),
                    taskDesc: taskInput,
                },
            ]);
            setTaskInput("");
        }
    };

    // Removes the task from state when the user clicks on the related 'x'
    const handleRemoveTask = (id) => {
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
    };

    const tasksJSX = tasks.map((task) => {
        return (
            <div className="task-wr" id={task.id} key={task.id}>
                <p className="task-item">{task.taskDesc}</p>
                <img
                    src={CrossIcon}
                    alt="cross icon"
                    className="delete-task-icon"
                    data-id={task.id}
                    onClick={(e) => handleRemoveTask(e.target.dataset.id)}
                />
            </div>
        );
    });

    const openAddPhotoModel = () => {
        toggleModalState("addPhotoModal")
    }

    // Shows a modal with an enlarged version of the image
    const handleShowEnlargeImgModal = async (photo) => {
        await setCurrentImgToEnlarge(photo)
        toggleModalState("enlargeImgModal")
    }

    const photosJSX = modalPhotos.map((photo, index) => {
        return (
            <button type="button" className="add-log-photo-wr" key={index} onClick={() => handleShowEnlargeImgModal(photo)}>
                <img src={photo.smallImageURL} className="log-photo" alt="Log Photo" />
            </button>
        )
    })

    return (
        <>
            <form onSubmit={handleSubmitLog}>
                <div className="modal-overlay" style={isAddLogVisible ? { display: "block" } : { display: "none" }}></div>
                <div className="modal-wr" id="add-log-modal" style={isAddLogVisible ? { display: "block" } : { display: "none" }}>
                    <div className="modal-header">
                        <h2 className="modal-title heading-xl">Add New Log</h2>
                        <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                    </div>
                    <div className="date-wr">
                        <div className="modal-date-wr">
                            <h3 className="heading-m">Date</h3>
                            <input
                                autoComplete="off"
                                type="date"
                                name="modal-date-input"
                                id="add-log-date"
                                onChange={(e) => setDate(e.target.value)}
                                value={date}
                                required
                            />
                        </div>
                    </div>
                    <div className="modal-mileage-cost-wr">
                        <div className="modal-mileage-wr">
                            <h3 className="heading-m">Mileage / Hrs</h3>
                            <input
                                autoComplete="off"
                                type="number"
                                className="modal-input"
                                placeholder="0"
                                id="add-mileage-input"
                                onChange={(e) => setMileage(e.target.value)}
                                value={mileage}
                                required
                                />
                        </div>
                        <div className="modal-cost-wr">
                            <h3 className="heading-m">Cost</h3>
                            <input
                                autoComplete="off"
                                type="number"
                                className="modal-input"
                                placeholder="0"
                                id="add-cost-input"
                                onChange={(e) => setCost(e.target.value)}
                                value={cost}
                            />
                        </div>
                    </div>
                    <div className="modal-shop-wr">
                        <h3 className="heading-m">Carried Out By Shop?</h3>
                        <select
                            className="dropdown-list"
                            id="modal-shop"
                            name="modal-shop"
                            value={completedByShop}
                            onChange={(e) => setCompletedByShop(e.target.value)}
                        >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                    <div className="modal-tasks-wr">
                        <h3 className="heading-m">Tasks</h3>
                        <div className="task-list-wr">
                            {tasksJSX}
                        </div>
                        <div className="task-input-wr">
                            <input
                                autoComplete="off"
                                type="text"
                                id="modal-add-task-input"
                                className="modal-add-task-input"
                                placeholder="e.g Changed Oil"
                                onChange={(e) => setTaskInput(e.target.value)}
                                value={taskInput}
                                maxLength={55}
                            />
                        </div>
                        <button type="button" className="secondary-btn" onClick={handleAddNewTask}>Add Task</button>
                    </div>
                    <div className="modal-notes-wr">
                        <h3 className="heading-m">Additional Notes</h3>
                        <textarea
                            name="add-log-notes"
                            id="add-log-notes"
                            placeholder="e.g  I used Motorex 10W 40 fully synthetic oil"
                            className="modal-notes"
                            onChange={(e) => setNotes(e.target.value)}
                            value={notes}
                        />
                    </div>
                    <div className="modal-photos-wr">
                        <div className="photos-heading">
                            <h3 className="heading-m">Photos</h3>
                            <p className="photos-count">{modalPhotos.length} of 6</p>
                        </div>
                        <div className="log-photos-grid">
                            {photosJSX && photosJSX}
                            {/* Hide add photo btn if user already has 3 photos */}
                            {modalPhotos.length < 6 && 
                            <button type="button" className="add-photo-btn" onClick={openAddPhotoModel}>
                                <img src={AddPhotoIcon} alt="add photo icon" />
                            </button>}
                        </div>
                    </div>
                    <button type="submit" className="primary-btn" id="create-log-btn">Create Log</button>
                    {errMsg && <p className="err-msg body-s">{errMsg}</p>}
                </div>
            </form>

        </>
    );
}
