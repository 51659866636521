import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase'; // Ensure this path points to your Firebase setup
import { toast } from "react-toastify";

const provider = new GoogleAuthProvider();

const handleSignIn = async () => {
  try {
    // Initiate sign-in using a pop-up window
    const result = await signInWithPopup(auth, provider);
    // Handle the result of the sign-in, like accessing user details
    const user = result.user;
    // Direct user to /profile
    window.location.href = "/profile/logbook";
    toast.success("User logged in successfully", {
      position: "top-center",
    });
    // Display a success message or redirect to another page
  } catch (error) {
    console.error('Error during sign-in:', error);
    // Handle errors, such as by displaying a message to the user
  }
};

export default function GoogleSignIn() {
  return (
    <button type="button" class="login-with-google-btn" onClick={handleSignIn} >
  Sign in with Google
    </button>
  );
}
