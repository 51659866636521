const apiKey = 'oPdJ9vC7SsylwVK8nrPabw==P5oVkUIRR3vJ9mae';

const fetchData = async (url) => {
    try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey
          }
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        return result

    }
    catch (err) {
        console.log(err)
    }
}

 // Get today's date in the format YYYY-MM-DD
 function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  // Pad month and day with leading zeros if needed
  if (month < 10) {
      month = `0${month}`;
  }
  if (day < 10) {
      day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
}

  // Check addBike input exists and prohibits the use of special chars
  function validateInput(input) {
    const regex = /^[A-Za-z0-9 ]+$/;
  
    if (input.length > 0 && regex.test(input)) {
      return true; 
    } else {
      return false; 
    }
  }   

  function verifyModalPhotos(modalPhotos, setModalPhotos, date) {
    return new Promise((resolve, reject) => {
      try {
        const arrCopy = [...modalPhotos];
  
        const updatedPhotos = arrCopy.map((photo) => {
          if (photo.datestamp === date) {
            return { ...photo, isVerified: true };
          }
          return photo;
        });

        let allPhotosAreVerified = true
        updatedPhotos.forEach(photo => {
          if (photo.isVerified === false) {
            allPhotosAreVerified = false
          }
        });
        if (updatedPhotos.length === 0) {
          allPhotosAreVerified = false
        }

        setModalPhotos(updatedPhotos);
        resolve({updatedPhotos, allPhotosAreVerified}); // Resolve with the updated array
      } catch (error) {
        reject(error); // Reject in case of any error
      }
    });
  }

  function validateVIN(vin) {
    // Check if the VIN is exactly 17 characters long
    if (vin.length !== 17) {
      return false;
    }
  
    // Check if the VIN contains only allowed characters (A-Z, 0-9)
    const vinRegex = /^[A-HJ-NPR-Z0-9]{17}$/;
    if (!vinRegex.test(vin)) {
      return false;
    }
  
    return true
  }

export {fetchData, 
        getTodayDate,
        validateInput,
        verifyModalPhotos,
        validateVIN}