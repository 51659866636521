import React from "react";
import { useContext } from "react";
import { WindowContext } from "../../context/WindowContext";
import CloseModalBtn from '../../assets/icon-cross.svg'
import VerifiedIcon from '../../assets/icon-photo-is-verified.svg'
import LoadingSpinner from '../Modules/LoadingSpinner'

export default function EnlargeImgModal () {

    const { modalState, toggleModalState, currentImgToEnlarge} = useContext(WindowContext)
    const isEnlargeImgModalVisible = modalState.enlargeImgModal

    // Closes confirm modal
    const handleCloseModal = () => {
        toggleModalState("enlargeImgModal")
    }

    return (
        <>
            <div className="modal-overlay" style={isEnlargeImgModalVisible ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="add-bike-modal" style={isEnlargeImgModalVisible ? {display : "block"} : {display : "none"}}>
                <div className="enlarge-photo-wr">
                    <div className="header-wr">
                        {!modalState.addNewLogModal && 
                        <div className="verified-info-wr">
                            {currentImgToEnlarge?.isVerified ? 
                                <>
                                    <div className="verified-photo-title-wr">                                
                                          <h2 className="heading-xl">Verified Photo</h2>
                                          <img src={VerifiedIcon} alt="verified-icon" className="verified-icon" />
                                        </div>  
                                </>
                            :
                            <>
                                   <h2 className="heading-xl">Photo is not verified</h2>
                                </>
                                }
                        </div>}
                        <img src={CloseModalBtn} alt="close icon" className="close-icon" onClick={handleCloseModal}/>
                    </div>
                    {currentImgToEnlarge ? 
                        <img src={currentImgToEnlarge?.largeImageURL} alt="close icon" className="enlarged-bike-img" />
                        :
                        // Display Loading Spinnner if photo is still uploading 
                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>
                            <LoadingSpinner />
                        </div>}
                </div>
                    {currentImgToEnlarge?.isVerified ? 
                        <p className="verified-photo-msg text-center">This photo was taken on <strong>{currentImgToEnlarge?.datestamp.split("-").reverse().join("/")}</strong></p>
                        :
                        <p className="text-center">Photo was uploaded from a Camera roll</p>} 
            </div>
        </>
    )
}
