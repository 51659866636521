import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import AddPhotoIcon from '../../assets/icon-add-photo.svg'
import { SearchManualsLib } from "../Modules/SearchManualLib";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import SpecsIcon from '../../assets/icon-specs.svg'
import CalenderIcon from '../../assets/icon-calender.svg'
import ClickersIcon from '../../assets/icon-clickers.svg'
import GuageIcon from '../../assets/icon-guage.svg'
import TodoIcon from '../../assets/icon-todo.svg'
import EditIcon from '../../assets/icon-edit.svg';
import { v4 as uuidv4 } from 'uuid';
import CrossIcon from "../../assets/icon-cross.svg";

const Dashboard = () => {

  const { currentBike, setCurrentBike, toggleModalState, setCurrentPage, setLoading, updateCurrentBikeDashboard} = useContext(WindowContext)
  const { userDetails } = useContext(UserContext) 
  const specCount = currentBike && Object.keys(currentBike.specs).length
  const bikeCount = userDetails.bikes.length

  const [bikePhoto, setBikePhoto] = useState("")
  const [registration, setRegistration] = useState(currentBike?.dashboard.registration)
  const [VIN, setVIN] = useState(currentBike?.dashboard.VIN)
  const [motExpiryDate, setMotExpiryDate] = useState(currentBike?.dashboard.motExpiry)
  const [taxExpiryDate, setTaxExpiryDate] = useState(currentBike?.dashboard.taxExpiry)
  const [tyrePressureFront, setTyrePressureFront] = useState(currentBike?.dashboard.tyrePressureFront)
  const [tyrePressureRear, setTyrePressureRear] = useState(currentBike?.dashboard.tyrePressureRear)
  const [compressionFrontHS, setCompressionFrontHS] = useState(currentBike?.dashboard.compressionFrontHS)
  const [compressionRearHS, setCompressionRearHS] = useState(currentBike?.dashboard.compressionRearHS)
  const [compressionFrontLS, setCompressionFrontLS] = useState(currentBike?.dashboard.compressionFrontLS)
  const [compressionRearLS, setCompressionRearLS] = useState(currentBike?.dashboard.compressionRearLS)
  const [reboundFrontHS, setReboundFrontHS] = useState(currentBike?.dashboard.reboundFrontHS)
  const [reboundRearHS, setReboundRearHS] = useState(currentBike?.dashboard.reboundRearHS)
  const [reboundFrontLS, setReboundFrontLS] = useState(currentBike?.dashboard.reboundFrontLS)
  const [reboundRearLS, setReboundRearLS] = useState(currentBike?.dashboard.reboundRearLS)
  const [todoList, setTodoList] = useState(currentBike?.dashboard.todoList);
  const [todoListInput, setTodoListInput] = useState("");
  const [todoListEdited, setTodoListEdited] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const regInputRef = useRef(null)

  // If user has no bikes, redirect user to logbook
  if (userDetails.bikes.length === 0) {
    window.location.href = "/profile/logbook";
  }

  useEffect(() => {
    setCurrentPage("dashboard")
    setBikePhoto(currentBike.dashboard.bikeImagePath)
  }, [])


  // Displays Bike Specs modal
  const handleBikeSpecsBtn = () => {
    toggleModalState("bikeSpecsModal")
  }

  // Changes UI to allow user to edit the dashboard values
  const handleEditDashboard = () => {
    setIsEditing(true)
  }
  
  // Saves new dashboard values to currentBike
  const handleSaveDashboard = (e) => {
    e.preventDefault();
    updateUsersDashboard()
  }

  const updateUsersDashboard = () => {
    
    setLoading(true)
    try {
      const newDashboard = {
        bikeImagePath: bikePhoto,
        motExpiry: motExpiryDate,
        taxExpiry: taxExpiryDate,
        tyrePressureFront: tyrePressureFront,
        tyrePressureRear: tyrePressureRear,
        compressionFrontHS: compressionFrontHS,
        compressionRearHS: compressionRearHS,
        compressionFrontLS: compressionFrontLS,
        compressionRearLS: compressionRearLS,
        reboundFrontHS: reboundFrontHS,
        reboundRearHS: reboundRearHS,
        reboundFrontLS: reboundFrontLS,
        reboundRearLS: reboundRearLS,
        todoList: todoList,
        registration: registration,
        VIN: VIN
      }
      updateCurrentBikeDashboard(newDashboard)
      setLoading(false)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsEditing(false)
      setTodoListEdited(false)
    }
  }

  // Adds a new task to state and resets task input field
  const handleAddNewTask = () => {
      
    if (todoListInput.trim() !== "") {  
        try {        
          setTodoList((prevList) => {             
            return [
                ...prevList,
                {
                    id: uuidv4(),
                    taskDesc: todoListInput,
                }]
              });
                      
            setTodoListEdited(true)
            setTodoListInput("");
        }
        catch(err) {
        }
      }
  };

  const handleOpenAddVINModal = () => {
    toggleModalState("AddVINModal")
  }

  // Removes the task from state when the user  on the related 'x'
  const handleRemoveTask = (id) => {
    
    setTodoList((prevTasks) => prevTasks.filter((task) => task.id !== id));
    setTodoListEdited(true)
  };

  const openAddPhotoModel = () => {
    toggleModalState("addPhotoModal")
  }

  const handleSaveRegToCurrentBike = () => {
    setCurrentBike(prev => {
      return {
        ...prev,
        dashboard: {
          ...prev.dashboard,
          registration: registration
        }
      }
    })
  }

  const handleRegInputChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9 ]/g, ''); // Added space in the regex
    setRegistration(value);
  };

  const todoListJSX = todoList.map((task) => {
    return (
        <div className="task-wr" id={task.id} key={task.id}>
            <p className="task-item">{task.taskDesc}</p>
            <img
                src={CrossIcon}
                alt="cross icon"
                className="delete-task-icon"
                data-id={task.id}
                onClick={(e) => handleRemoveTask(e.target.dataset.id)}
            />
        </div>
    );
  });

  return (
    <>
    {bikeCount > 0 && 
      <div className="dash-wr">
      <div className="dash-bike-hero">
        <div className='dash-add-photo-wr'>
        {bikePhoto === "" || isEditing ?
          <button type="button" className="add-bike-photo-btn" onClick={openAddPhotoModel}>
              <img src={AddPhotoIcon} alt="add photo icon" />
          </button>
          :
          <div className="dash-photo-wr">
              <img src={bikePhoto} className="dash-photo" alt="Log Photo" />
          </div>
        }
          <h1 className='dash-title text-center'>{currentBike && `${currentBike.specs.make}`} {currentBike.specs.model !== null && `${currentBike.specs.model}`}</h1>
        {/* Show VIN number or add VIN btn  */}
        {!VIN || VIN === "" || VIN === null || VIN === undefined ? 
          <button className="add-vin-btn" onClick={handleOpenAddVINModal}>
            + VIN Number
          </button>
        :
          <p className="vin-number">VIN: {VIN}</p>
        }
        <input
            autoComplete="off"
            type="text"
            id="registration"
            className="reg-input"
            ref={regInputRef}
            placeholder="VEHICLE REG"
            style={{ textTransform: 'uppercase' }}
            value={registration ? registration : ""}
            spellCheck="false"
            onChange={handleRegInputChange}
            onBlur={handleSaveRegToCurrentBike}
            maxLength={10}
            pattern="[A-Za-z0-9]*" 
            inputMode="text" 
        />
        </div>
        <div className="dash-manual-specs-wr">
          {/* Only show Specs btn if it is a found bike from the API */}
          {specCount > 3 && 
            <button className="bike-spec-btn body-l" onClick={handleBikeSpecsBtn}>
              <img src={SpecsIcon} alt="Specs icon" className="specs-icon" />
              Bike Specs
          </button>}
          {currentBike && <SearchManualsLib bike={currentBike}/>}
        </div>
      </div>
      <form onSubmit={handleSaveDashboard}>
      <div className="dash-main-wr">
        <div className="dash-edit-btn-wr">
          {/* Display Edit or Save btn based on isEditing */}
            <button className="dash-save-btn body-l" type="submit"  style={!isEditing ? { display: "none" } : {}}>
                Save
            </button>
            <button className="dash-edit-btn body-l" type="button" onClick={handleEditDashboard}  style={isEditing ? { display: "none" } : {}}>
                edit
                <img src={EditIcon} alt="edit icon" className="edit-icon" />
            </button>
        </div>
        <div className="dash-main">
            <div className="dash-mot-tax-expiry-wr dash-tile">
              <div className="dash-tile-header">
                <img src={CalenderIcon} alt="Calender Icon" />
                <h2 className="heading-l">MOT Expiry Date</h2>
              </div>
              <div className="dash-tile-main">
                {!isEditing ? 
                  <p id="mot-expiry">{motExpiryDate.split("-").reverse().join("/")}</p>
                :
                  <input
                    autoComplete="off"
                    type="date"
                    name="mot-expiry-date-input"
                    id="mot-expiry-date-input"
                    onChange={(e) => setMotExpiryDate(e.target.value)}
                    value={motExpiryDate}
                    required
                  />
                }
                <h2 className="heading-l">Tax Expiry Date</h2>
                {!isEditing ? 
                  <p id="mot-expiry">{taxExpiryDate.split("-").reverse().join("/")}</p>
                :
                  <input
                    autoComplete="off"
                    type="date"
                    name="tax-expiry-date-input"
                    id="tax-expiry-date-input"
                    onChange={(e) => setTaxExpiryDate(e.target.value)}
                    value={taxExpiryDate}
                    required
                  />
                }
              </div>
            </div>
            <div className="dash-tyre-pressures-wr dash-tile">
              <div className="dash-tile-header">
                <img src={GuageIcon} alt="Guage Icon" />
                <h2 className="heading-l">Tyre Pressures</h2>
              </div>
              <div className="pressure-values flex-around">
                <p className="dash-prop">Front:</p>
                {!isEditing ? 
                  <p id="front-tyre-pressure">{`${tyrePressureFront} psi`}</p>
                :
                  <input
                    autoComplete="off"
                    type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                    className="dash-input"
                    placeholder={tyrePressureFront}
                    id="tyre-pressure-front-input"
                    onChange={(e) => setTyrePressureFront(e.target.value)}
                    value={tyrePressureFront}
                    required
                  />
                }
                <p className="dash-prop">Rear:</p>
                {!isEditing ? 
                  <p id="rear-tyre-pressure">{`${tyrePressureRear} psi`}</p>
                :
                  <input
                    autoComplete="off"
                    type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                    className="dash-input"
                    placeholder={tyrePressureRear}
                    id="tyre-pressure-rear-input"
                    onChange={(e) => setTyrePressureRear(e.target.value)}
                    value={tyrePressureRear}
                    required
                    />
                  }
              </div>
            </div>
            <div className="dash-suspension-clickers-wr dash-tile">
                <div className="dash-tile-header">
                  <img src={ClickersIcon} alt="Clickers Icon" />
                  <h2 className="heading-l">Suspension Clickers</h2>
                </div>
                <h3>Compression (clicks)</h3>
                <div className="hs-compression-values flex-around">
                  <p className="dash-prop">Front (HS):</p>
                  {!isEditing ? 
                    <p id="front-hs-comp-clicks">{`${compressionFrontHS} `}</p>
                  :
                    <input
                      autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={compressionFrontHS}
                      id="front-hs-comp-input"
                      onChange={(e) => setCompressionFrontHS(e.target.value)}
                      value={compressionFrontHS}
                      required
                    />
                  }
                  <p className="dash-prop">Rear (HS):</p>
                  {!isEditing ? 
                    <p id="rear-hs-comp-">{`${compressionRearHS} `}</p>
                  :
                    <input
                      autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={compressionRearHS}
                      id="rear-hs-comp-input"
                      onChange={(e) => setCompressionRearHS(e.target.value)}
                      value={compressionRearHS}
                      required
                    />
                  }
                </div>
                <div className="ls-compression-values flex-around">
                  <p className="dash-prop">Front (LS):</p>
                  {!isEditing ? 
                    <p id="front-ls-comp-">{`${compressionFrontLS} `}</p>
                  :
                    <input
                    autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={compressionFrontLS}
                      id="front-ls-comp-input"
                      onChange={(e) => setCompressionFrontLS(e.target.value)}
                      value={compressionFrontLS}
                      required
                      />
                  }
                  <p className="dash-prop">Rear (LS):</p>
                  {!isEditing ? 
                    <p id="rear-ls-comp-">{`${compressionRearLS} `}</p>
                  :
                  <input
                      autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={compressionRearLS}
                      id="rear-ls-comp-input"
                      onChange={(e) => setCompressionRearLS(e.target.value)}
                      value={compressionRearLS}
                      required
                    />
                  }
                </div>
                <h3>Rebound (clicks)</h3>
                <div className="hs-rebound-values flex-around">
                  <p className="dash-prop">Front (HS):</p>
                  {!isEditing ? 
                    <p id="front-hs-rebound-">{`${reboundFrontHS} `}</p>
                    :
                    <input
                      autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={reboundFrontHS}
                      id="front-hs-rebound-input"
                      onChange={(e) => setReboundFrontHS(e.target.value)}
                      value={reboundFrontHS}
                      required
                      />
                    }
                  <p className="dash-prop">Rear (HS):</p>
                  {!isEditing ? 
                    <p id="rear-hs-rebound-">{`${reboundRearHS} `}</p>
                  :
                    <input
                    autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={reboundRearHS}
                      id="rear-hs-rebound-input"
                      onChange={(e) => setReboundRearHS(e.target.value)}
                      value={reboundRearHS}
                      required
                    />
                  }
                </div>
                <div className="ls-rebound-values flex-around">
                  <p className="dash-prop">Front (LS):</p>
                  {!isEditing ? 
                    <p id="front-ls-rebound-">{`${reboundFrontLS} `}</p>
                  :
                    <input
                      autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={reboundFrontLS}
                      id="front-ls-rebound-input"
                      onChange={(e) => setReboundFrontLS(e.target.value)}
                      value={reboundFrontLS}
                      required
                    />
                  }
                  <p className="dash-prop">Rear (LS):</p>
                  {!isEditing ? 
                    <p id="rear-ls-rebound-">{`${reboundRearLS} `}</p>
                  :
                    <input
                    autoComplete="off"
                      type="text" maxlength="2" inputmode="numeric" pattern="\d*"
                      className="dash-input"
                      placeholder={reboundRearLS}
                      id="rear-ls-rebound-input"
                      onChange={(e) => setReboundRearLS(e.target.value)}
                      value={reboundRearLS}
                      required
                    />
                  }
                </div>
            </div>
          <div className="dash-todo-list-wr dash-tile">
              <div className="dash-tile-header">
                <img src={TodoIcon} alt="Todo Icon" />
                <h2 className="heading-l">Todo List</h2>
              </div> 
                <div className="modal-todoList-wr">
                          <h3 className="heading-m">Tasks</h3>
                          <div className="task-list-wr">
                              {todoListJSX}
                          </div>
                          <div className="task-input-wr" >
                              <input
                                  autoComplete="off"
                                  type="text"
                                  id="dash-add-task-input"
                                  className="modal-add-task-input"
                                  placeholder="e.g Changed Oil"
                                  onChange={(e) => setTodoListInput(e.target.value)}
                                  value={todoListInput}
                                  style={{textAlign: "start"}}
                              />
                          </div>
                          {todoListEdited && 
                            <button className="primary-btn" type="submit" >
                              Save
                            </button>
                          }
                          <button type="button" className="secondary-btn" id="dash-add-task-btn" onClick={handleAddNewTask}>Add Task</button>
                      </div>
            </div>
        </div>
      </div>
      
      </form> 
    </div>      
  }
  </>
  )
};

export default Dashboard;
