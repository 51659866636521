import { getStorage, ref, uploadString, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";

const uploadLogImage = async (imageDataUrl, imageSize, userId, currentBikeId, logId) => {
  const storage = getStorage();
  const dir = imageSize === "small" ? "smallImages" : "largeImages"
  const storageRef = ref(storage, `images/${userId}/${currentBikeId}/${logId}/${dir}/${Date.now()}.png`);

  try {
    await uploadString(storageRef, imageDataUrl, 'data_url');
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image", error);
    return null;
  }
};

const uploadBikeImage = async (imageDataUrl, userId, currentBikeId) => {
  const storage = getStorage();
  const storageRef = ref(storage, `images/${userId}/${currentBikeId}/dashboard/bike-img.png`);

  try {
    await uploadString(storageRef, imageDataUrl, 'data_url');
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image", error);
    return null;
  }
};

const saveImageUrlToLog = async (userId, imageUrl) => {
  const db = getFirestore();
  const userLogRef = doc(db, 'Users', userId);

  try {
    await updateDoc(userLogRef, {
      photos: arrayUnion(imageUrl)
    });
  } catch (error) {
    console.error("Error saving image URL to Firestore", error);
  }
};

const deleteImageFromStorage = async (imagePath) => {
  
  const storage = getStorage();
  const imageRef = ref(storage, imagePath);

  try {
    // Delete the file
    await deleteObject(imageRef);
    return true; // Return true if the deletion was successful
  } catch (error) {
    return false; // Return false if there was an error
  }
};

const deleteAllCurrentBikeImagesFromStorage = async (userId, currentBikeId) => {
  try {
    // Define currentBike folder
    const storage = getStorage();
    const folderRef = ref(storage, `images/${userId}/${currentBikeId}`);
    
    // Loop through all logs and delete all images
    const listResults = await listAll(folderRef);
    
    listResults.prefixes.forEach(async logRef => {
      const logFolderRef = ref(storage, logRef._location.path_)
      const logListResults = await listAll(logFolderRef);
      
      logListResults.prefixes.forEach(async folderRef => {
        const sizeImgfolderRef = ref(storage, folderRef._location.path_)
        const sizeImageListResults = await listAll(sizeImgfolderRef);
        const deletePromises = sizeImageListResults.items.map((fileRef) => deleteObject(fileRef));
        await Promise.all(deletePromises);
      })
    })


  } catch (error) {
    console.error("Error deleting folder contents:", error);
  }
};

const deleteAllCurrentLogImagesFromStorage = async (userId, currentBikeId, currentLogId) => {
  try {
    // Define currentBike folder
    const storage = getStorage();
    const folderRef = ref(storage, `images/${userId}/${currentBikeId}/${currentLogId}`);
    
    const listResults = await listAll(folderRef);
    listResults.prefixes.forEach(async folderRef => {
      const sizeImgfolderRef = ref(storage, folderRef._location.path_)
      const sizeImageListResults = await listAll(sizeImgfolderRef);
      const deletePromises = sizeImageListResults.items.map((fileRef) => deleteObject(fileRef));
      await Promise.all(deletePromises);
    })

  } catch (error) {
    console.error("Error deleting folder contents:", error);
  }
};


export { saveImageUrlToLog,
         uploadLogImage, 
         uploadBikeImage, 
         deleteImageFromStorage,
        deleteAllCurrentBikeImagesFromStorage,
        deleteAllCurrentLogImagesFromStorage} 