// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyASSi3TNx3PHRI-uSV7pgU_FS57GxjAisg",
  authDomain: "motodiary-361a3.firebaseapp.com",
  projectId: "motodiary-361a3",
  storageBucket: "motodiary-361a3.appspot.com",
  messagingSenderId: "108013738527",
  appId: "1:108013738527:web:e68c28382d23776452932a"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth();
export const db=getFirestore(app);
export default app;